.slider
{
    width: 850px !important;
}
.slider-control-centerright button
{
    
    background: url(./img/arrows_rigth.png) no-repeat !important;
    display: block;
    width: 30px !important;
    height: 30px !important;
    text-indent: -9999px;
    border: 0;
    margin-right: 20px;
  
}
.slider-control-centerleft button
{
    display: block;
    width: 30px;
    height: 30px;
    background: url(./img/arrows_left.png) no-repeat !important;
    text-indent: -9999px;
    border: 0;
    margin-left: 20px;
   
}
.slider-control-centerleft button:hover
{
    display: block;
    width: 30px;
    height: 30px;
    background: url(./img/arrows_left_hover.png) no-repeat !important;
    text-indent: -9999px;
    border: 0;
    margin-left: 20px;
   
}
.slider-control-centerright button:hover
{
    display: block;
    width: 30px;
    height: 30px;
    background: url(./img/arrows_rigth_hover.png) no-repeat !important;
    text-indent: -9999px;
    border: 0;
    margin-left: 20px;
   
}